import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import React from 'react'

import CarmatchButton from '../../atoms/CarmatchButton'
import WhatsAppIcon from '../../atoms/icons/WhatsApp'

export default function TalkSpecialistButton() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button startIcon={<WhatsAppIcon />} onClick={handleClickOpen} fullWidth variant="contained" color="success">
        Fale com o especialista
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText align="center" sx={{ p: 3 }}>
            Queremos entender o que está buscando para que o nosso especialista possa te ajudar! <br />
            Basta clicar no botão e inicie sua jornada.
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <CarmatchButton isPageTransitioning={false} />
          <Button onClick={handleClose} variant="outlined">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
